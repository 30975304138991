import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeId from '@angular/common/locales/id';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {ClarityModule} from '@clr/angular';
import {createErrorHandler, TraceService} from '@sentry/angular';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './core/auth/auth.service';
import {InterceptorService} from './core/interceptor/interceptor.service';

registerLocaleData(localeId, 'id');

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ClarityModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'id-ID'},
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    provideNgxMask(),
    AuthService,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
