import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = this.adjustRequest(req);

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          return this.authService
            .refreshToken(this.authService.auth.refreshToken)
            .pipe(switchMap(() => next.handle(request)));
        } else if (err.status === 400) {
          if (err.error.error === 'invalid_grant') {
            console.warn(err);
            void this.authService.signOut();
            return throwError(() => new Error('invalid grant!!! returning to sign-in page'));
          } else {
            console.warn(err);
            return throwError(() => new Error('unknown error'));
          }
        } else {
          console.error(err);
          return throwError(() => new Error('intercepted error'));
        }
      })
    );
  }

  adjustRequest(request: HttpRequest<any>): HttpRequest<any> {
    const Authorization =
      request.url === '/token'
        ? 'Basic bW9iaWxlOlhwZWR1MTIz'
        : `Bearer ${this.authService.auth.accessToken}`;

    return request.clone({url: environment.endpoint + request.url, setHeaders: {Authorization}});
  }
}
